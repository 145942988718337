.lds-ripple {
  width: 64px;
  height: 64px;
  display: inline-block;
  position: relative;
}

.lds-ripple div {
  opacity: 1;
  border: 4px solid #add8e6;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, .2, .8, 1) infinite;
  position: absolute;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes lds-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
    top: 28px;
    left: 28px;
  }

  100% {
    width: 58px;
    height: 58px;
    opacity: 0;
    top: -1px;
    left: -1px;
  }
}

/*# sourceMappingURL=index.2abc0fd1.css.map */
